var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"transition-swing",attrs:{"elevation":hover ? 0 : 0,"outlined":"","color":_vm.color + ' lighten-4'}},[_c('v-card-subtitle',{staticStyle:{"height":"70px"}},[_c('p',{staticClass:"text--primary"},[_c('v-icon',[_vm._v(" mdi-account")]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":("/account/" + (_vm.post.author.username))}},[_vm._v(" "+_vm._s(_vm.post.author.username)+" ")])],1),_c('p',{staticClass:"text--accent-1 text--secondary",staticStyle:{"margin-left":"5px","margin-top":"-15px"}},[_vm._v(" "+_vm._s(_vm.dateCreated(_vm.post.date_created))+" ")])]),(!_vm.edit)?_c('v-card-text',[_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.post.text))]),(_vm.post.edited)?_c('p',{staticClass:"text--disabled",staticStyle:{"margin-top":"-18px"}},[_vm._v(" bearbeitet ")]):_vm._e()]):_c('v-card-text',[_c('v-textarea',{attrs:{"rows":"2","solo":"","flat":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updatePost($event)}},model:{value:(_vm.newText),callback:function ($$v) {_vm.newText=$$v},expression:"newText"}})],1),_c('v-card-actions',_vm._g(_vm._b({staticClass:"clickable text-body-2 text--secondary"},'v-card-actions',attrs,false),on),[(_vm.post.children_count === 0)?_c('span',{},[_vm._v("noch keine Kommentare")]):_vm._e(),(_vm.post.children_count === 1)?_c('span',{},[_vm._v(_vm._s(_vm.post.children_count)+" Kommentar")]):_vm._e(),(_vm.post.children_count > 1)?_c('span',[_vm._v(_vm._s(_vm.post.children_count)+" Kommentare")]):_vm._e(),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-comment")]),_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"position":"absolute","top":"6px","right":"6px"}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',[(_vm.post.is_author)?_c('v-list-item',{on:{"click":function($event){_vm.newText = _vm.post.text;
                  _vm.edit = !_vm.edit;}}},[_vm._v(_vm._s(_vm.$t("postings.edit"))+" ")]):_vm._e(),(_vm.post.is_author)?_c('v-list-item',{on:{"click":_vm.deletePost}},[_vm._v(_vm._s(_vm.$t("postings.delete"))+" ")]):_vm._e(),_c('report-dialog',{attrs:{"item":_vm.post,"kind":"post"}})],1)],1)],1)],1)]}}],null,true)})]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"overline",class:_vm.color + ' lighten-4'},[_vm._v(" "+_vm._s(_vm.$t("postings.commentTitle"))+" ")]),_c('v-divider'),_c('v-card-title',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.post.text)+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-4"},[(!!_vm.children && _vm.children.length === 0)?_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.$t('postings.noCommentMessage', { user: _vm.post.author.username })
        )}}):_vm._e(),_c('v-list',[_c('v-scale-transition',{attrs:{"group":""}},_vm._l((_vm.children),function(answer,index){return _c('v-list-item',{key:index,staticClass:"grey lighten-3 rounded-lg  ma-2"},[_c('v-list-item-content',[_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(answer.text))]),_c('v-list-item-subtitle',[_c('router-link',{staticClass:"text-decoration-none font-weight-bold text--secondary",attrs:{"to":("/account/" + (answer.author.username))}},[_vm._v(" "+_vm._s(answer.author.username))]),_vm._v(", "+_vm._s(_vm.dateCreated(answer.date_created))+" ")],1)],1)],1)}),1)],1)],1),_c('v-spacer'),_c('v-card-actions',{staticClass:"overflow-x-hidden"},[_c('v-textarea',{staticClass:"post-textarea",attrs:{"rows":"1","auto-grow":"","placeholder":"Kommentieren ...","dense":"","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendAnswer($event)}},model:{value:(_vm.answerText),callback:function ($$v) {_vm.answerText=$$v},expression:"answerText"}}),_c('v-scroll-x-reverse-transition',[(!!_vm.answerText)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.sendAnswer}},[_c('v-icon',[_vm._v("mdi-send")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }